import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SgHeadings from "../components/sg-headings"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBed, faLocationDot, faShip } from "@fortawesome/free-solid-svg-icons"
import Reactmarkdown from "react-markdown"
import { BgImage } from "gbimage-bridge"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Enterprises from "../components/enterprises"
import Cta from "../components/call_to_action"



export default function IndexPage ({ data })
{
  
  const seo = {
    metaTitle: data.strapiIndex.headline,
    metaDescription: data.strapiIndex.heroText,
  }
 
  // Variables
  const idx = data.strapiIndex
  const background = getImage(idx.heroImage.localFile)
  const headline = idx.headline
  const shortDesc = idx.heroText
  const bodyImage = getImage(idx.bodyImage.localFile)
  const bodyText = idx.body.data.body
  const teaserOne = idx.productTeaserOne
  const teaserTwo = idx.productTeaserTwo
  const teaserThree = idx.productTeaserThree
  const brands = data.allStrapiBrand.nodes
  const testimonials = data.allStrapiTestimonial.nodes


  return (
    <Layout>
      <Seo seo={seo} />
      <div className="relative w-full h-full bg-gradient-to-r from-black to-cyan-700">
            <BgImage 
              image={background} 
              alt="Finnish Northern Lights" 
              className="absolute h-96 bg-center object-cover mix-blend-overlay" />
                <div className="container absolute top-0 left-1/2 -translate-x-1/2">
                  <SgHeadings title={headline} description={shortDesc} />
                </div>  
          </div>
        
      <div className="container pb-6 gap-2 grid md:grid-cols-3 pt-6 m-auto px-6 text-gray-600 md:px-12 xl:px-6">
        <div className="p-4 text-center rounded-xl shadow-md">
            <FontAwesomeIcon icon={faBed} className="text-3xl text-brandgreen" />
            <div className="text-xl text-center">Hotels</div>
            <div className="p-4 text-center">{teaserOne}</div>
            <button id="hotel-services" type="submit" className="bg-brandblue text-center text-white py-2 px-4 rounded-xl focus:ring focus:ring-blue-300 hover:bg-cyan-700">
                     <Link to="/service/hotel">Hotel Services</Link>
            </button>        
        </div>

        <div className="p-4 text-center rounded-xl shadow-md">
            <FontAwesomeIcon icon={faLocationDot} className="text-3xl text-brandgreen" />
            <div className="text-xl text-center">Travel Agencies</div>
            <div className="p-4 text-center">{teaserTwo}</div>
            <button id="travel-agents" type="submit" className="bg-brandblue text-center text-white py-2 px-4 rounded-xl focus:ring focus:ring-blue-300 hover:bg-cyan-700">
                     <Link to="/service/travel-agent">Travel Agents</Link>
            </button>  
        </div>
        <div className="p-4 text-center rounded-xl shadow-md">
            <FontAwesomeIcon icon={faShip} className="text-3xl text-brandgreen"/>
            <div className="text-xl text-center">Transport companies</div>
            <div className="p-4  text-center">{teaserThree}</div>
            <button id="trasport-services" type="submit" className="bg-brandblue text-center text-white py-2 px-4 rounded-xl focus:ring focus:ring-blue-300 hover:bg-cyan-700">
                     <Link to="/service/transport">Transport services</Link>
            </button> 
        </div> 
        
    </div>
    <div className="container pt-6 pb-6 px-6 text-gray-600 md:px-12 xl:px-6">
          <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
              <div className="bg-gradient-to-r from-brandgreen to-transparent rounded-2xl">
                <GatsbyImage image={bodyImage} alt="Local companies selling local services" className="rounded-2xl shadow-md mix-blend-overlay" />
              </div>
                <div>
                  <h2 className="p-1 text-2xl text-brandgreen font-semibold md:text-4xl">We source local services from local companies</h2>
                  <div className="p-2 space-y-4 prose"><Reactmarkdown>{bodyText}</Reactmarkdown></div>
                </div>
          </div>
        </div>
    <Enterprises />
    <div className="container text-2xl text-brandgreen font-semibold text-center md:text-4xl">What they say</div>
    <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 pb-4">
      {testimonials.map(testimonial => (
      <figure key={testimonial.id} className="container mt-12 bg-slate-100 shadow-md rounded-2xl pt-2 relative">
            <GatsbyImage className="w-24 h-24 md:w-36 md:h-auto rounded-full float-right" 
            image={testimonial.headshot.localFile.childImageSharp.gatsbyImageData} 
            alt="Testimonial Toristy Travel" 
            />
            <div className="pt-6 md:p-6 space-y-4">
                <blockquote>
                <div className="text-base font-normal">
                   <Reactmarkdown>{testimonial.body.data.body}</Reactmarkdown>
                 </div>
                </blockquote>
                <figcaption className="font-medium">
                <div className="text-slate-700">
                    {testimonial.name}
                </div>
                <div className="text-sky-500">
                    <a href={testimonial.url}>{testimonial.title}</a>
                </div>
                </figcaption>
            </div>
        </figure>
      ))}
      </div>
      <div className="container text-2xl pt-4 text-brandgreen font-semibold text-center md:text-4xl">Some examples of our partners</div>
      <div className="container grid grid-cols-2 md:grid-cols-4 gap-4 pb-6">
      {brands.map(brand => (
      <figure key={brand.id} className="container mt-12 bg-white shadow-md rounded-2xl pt-2 relative">
            <a href={brand.url}>
            <GatsbyImage className="w-24 h-24 md:h-auto grayscale hover:grayscale-0" 
             image={brand.brandLogo.localFile.childImageSharp.gatsbyImageData}
            alt={brand.brandName} 
            /></a>
      </figure>
      ))}
      </div>
      <Cta />
    </Layout>
  )
}

export const query = graphql`
{
  allStrapiBrand {
    nodes {
      brandLogo {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      brandName
      id
      url
    }
  }
  allStrapiTestimonial {
    nodes {
      body {
        data {
          body
        }
      }
      headshot {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      name
      title
      url
    }
  }
  strapiIndex {
    body {
      data {
        body
      }
    }
    bodyImage {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    heroImage {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    heroText
    headline
    productTeaserOne
    productTeaserThree
    productTeaserTwo
  }
  strapiGlobal {
    siteDescription
  }
}

`

  

 
