// component dependencies
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandshake, faMapLocation, faPeopleRoof, faArrowsRotate } from "@fortawesome/free-solid-svg-icons"

const Enterprises = () => {
// Div class and font awesome icons for the standard list of features for experiences 
// Can be called using a dependency on the page and the tag <Peers>
// Will turn the spans into variables in Strapi later which allows for different languages

return (
<div className="container grid md:grid-cols-4 pt-6 m-auto px-6 text-gray-600 md:px-12 xl:px-6">
    <div className="p-4 text-center text-cyan-900 text-4xl">
        <FontAwesomeIcon icon={faHandshake} />
        <p className="p-4 text-center text-cyan-900 text-base">Earn commission and free to setup</p>
    </div>
    <div className="p-4 text-center text-cyan-900 text-4xl">
        <FontAwesomeIcon icon={faMapLocation} />
        <p className="p-4 text-center text-base">Locally sourced reputable businesses</p>
    </div>
    <div className="p-4 text-center text-cyan-900 text-4xl">
        <FontAwesomeIcon icon={faPeopleRoof} />
        <p className="p-4  text-center text-base">300,000+ services globally under one roof</p>
    </div>
    <div className="p-4 text-center text-cyan-900 text-4xl">
        <FontAwesomeIcon icon={faArrowsRotate} />
        <p className="p-4 text-center text-base">Realtime availability</p>
    </div>      
</div>
)    

}
export default Enterprises